import React, { useEffect, useRef, useState } from 'react';
import { Device } from 'types';
import { Button, Icon, IconNames, SettingsPanel } from 'components';
import { useTranslation } from 'react-i18next';
import SimpleBar from 'simplebar-react';
import moment from 'moment';

import { IJackpot } from 'core/widgets/Jackpot/types';
import {
  Wrapper,
  ButtonWrapper,
  ButtonText,
  UserValue,
  ItemWrapper,
  TableContainer,
  DateInfo,
  DateValue,
  ItemTitle,
  DatesWrapper,
  WinnerTableContainerScroll,
  StatusJackpot,
} from './styles';
import { IJackpotPanelComponentProps } from './types';
import { Tabs } from './components/WinnerTable/Tabs';
import { WinnersContent } from './WinnersContent';
import { isOldBrowserIOS } from 'hooks/getDefineDevEnv';

export const WinnersTableSettingsPanelComponent = ({
  currentJackpotPoints,
  nextJackpotPoints,
  isUserInStageJP,
  currentJackpot,
  nextJackpot,
  deviceType = Device.Desktop,
  uuid,
  hasUserInTop100,
  stage,
  outOfStagePlayers,
  activePlayers,
  onCloseClick,
  onClick,
}: IJackpotPanelComponentProps) => {
  const { t, i18n } = useTranslation();

  const defaultTabs = [
    {
      name: t('settings.jackpot.current_jp'),
      active: true,
    },
    {
      name: t('settings.jackpot.next_jp'),
      active: false,
    },
  ];

  const [tabs, setTabs] = useState(defaultTabs);
  const [selectedJackpotIndex, setSelectedJackpotIndex] = useState(0);

  const ref = useRef(null) as any;

  const [h, setH] = useState(400);
  useEffect(() => {
    // @ts-ignore
    const height = ref?.current?.getBoundingClientRect().height;
    setH(height);
  }, [ref]);

  if (!currentJackpot) {
    return null;
  }

  const onChangeTabs = (index: number) => {
    const newTabs = tabs.map((tab: any, i) => ({ ...tab, active: i === index }));
    setTabs(newTabs);
    // setSelectedJackpot(index ? nextJackpot : currentJackpot);
    setSelectedJackpotIndex(index);
  };

  const selectedJackpot = nextJackpot
    ? selectedJackpotIndex
      ? nextJackpot
      : currentJackpot
    : currentJackpot;
  const {
    drawnDate,
    collectionPeriodStartDate: fromDate,
    collectionPeriodEndDate: toDate,
    players: members,
  } = selectedJackpot as IJackpot;

  return (
    <Wrapper ref={ref}>
      <SettingsPanel onCloseClick={onCloseClick} deviceType={deviceType} hasVersionInfo={false}>
        <SettingsPanel.Title>
          {t('settings.jackpot.title')}{' '}
          {selectedJackpot?.isPaused && (
            <StatusJackpot $deviceType={deviceType}>{t('settings.jackpot.paused')}</StatusJackpot>
          )}
        </SettingsPanel.Title>
        <SettingsPanel.Item>
          <ItemWrapper $deviceType={deviceType} $isOldBrowser={isOldBrowserIOS()}>
            <ItemTitle>{t('settings.jackpot.bonus')}</ItemTitle>

            <ItemTitle>
              <UserValue>
                {selectedJackpotIndex ? nextJackpotPoints : currentJackpotPoints}{' '}
                {(selectedJackpotIndex
                  ? nextJackpotPoints > 0
                  : hasUserInTop100 || (stage && isUserInStageJP)) && (
                  <>
                    <Icon name={IconNames.Star} />
                    <span>{t('settings.jackpot.jp_top')}</span>
                  </>
                )}
              </UserValue>
            </ItemTitle>
          </ItemWrapper>
        </SettingsPanel.Item>

        <DatesWrapper $deviceType={deviceType}>
          <DateInfo $deviceType={deviceType}>
            {t('settings.jackpot.date')}:{' '}
            <DateValue $deviceType={deviceType}>
              {drawnDate && moment(new Date(drawnDate)).format('DD.MM.YYYY HH:mm')}
            </DateValue>
          </DateInfo>
          <DateInfo $deviceType={deviceType}>{t('settings.jackpot.collection_period')}: </DateInfo>
          <DateInfo $deviceType={deviceType}>
            <DateValue $deviceType={deviceType}>
              {fromDate && moment(new Date(fromDate)).format('DD.MM.YYYY HH:mm')}
            </DateValue>
            -
            <DateValue $deviceType={deviceType}>
              {toDate && moment(new Date(toDate)).format('DD.MM.YYYY HH:mm')}
            </DateValue>
          </DateInfo>
          {nextJackpot && (
            <DateInfo $deviceType={deviceType}>
              <Tabs tabs={tabs} onClick={onChangeTabs} />
            </DateInfo>
          )}
        </DatesWrapper>
        <TableContainer $hasPadding={Boolean(nextJackpot)}>
          <WinnerTableContainerScroll $deviceType={deviceType}>
            {deviceType === Device.Mobile || deviceType === Device.Tablet_Portrait ? (
              <SimpleBar
                style={{
                  height: `${h - (deviceType === Device.Mobile ? (nextJackpot ? 270 : 260) : nextJackpot ? 380 : isOldBrowserIOS() ? 350 : 330)}px`,
                }}
                autoHide={false}>
                <WinnersContent
                  uuid={uuid}
                  members={members}
                  stage={stage}
                  outOfStagePlayers={outOfStagePlayers}
                  activePlayers={activePlayers}
                  isCurrentJP={Boolean(!selectedJackpotIndex)}
                  deviceType={deviceType}
                  hasNextJackpot={Boolean(nextJackpot)}
                />
              </SimpleBar>
            ) : (
              <WinnersContent
                uuid={uuid}
                members={members}
                stage={stage}
                outOfStagePlayers={outOfStagePlayers}
                activePlayers={activePlayers}
                isCurrentJP={Boolean(!selectedJackpotIndex)}
                deviceType={deviceType}
                hasNextJackpot={Boolean(nextJackpot)}
              />
            )}
          </WinnerTableContainerScroll>
        </TableContainer>
        <ButtonWrapper>
          <Button onClick={onClick} fullWidth>
            <ButtonText $isSmallText={i18n.language === 'tl'}>
              <Icon name={IconNames.GameRule2} />
              {t('settings.jackpot.rules')}
            </ButtonText>
          </Button>
        </ButtonWrapper>
      </SettingsPanel>
    </Wrapper>
  );
};
