import React, { useState, useCallback, useEffect, useRef, createRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { LANGUAGES } from 'i18n';
import { isDesktop, isMobile } from 'react-device-detect';

import { SoundSettingsPanel } from 'widgets/SoundSettingsPanel';
import { VideoSettingsPanel } from 'widgets/VideoSettingsPanel';
import { MainSettingsPanel } from 'widgets/MainSettingsPanel';
import { settingsPanelSelector, shasClickJackpotSlotSelector } from 'core/widgets/SettingsPanel';
import { gameRuleSelector } from 'core/widgets/GameRules';
import { manageSettingsPanelAction } from 'core/widgets/SettingsPanel/actions';
import { getGameRulesAction, setIsShowJackpotRuleAction } from 'core/widgets/GameRules/actions';
import { GeneralSettingsPanel } from 'widgets/GeneralSettingsPanel';
import { PopupGameRules } from 'components';
import openFullScreen from 'utils/openFullScreen';
import closeFullScreen from 'utils/closeFullScreen';
import checkFullScreen from 'utils/checkFullScreen';
import { soundSettingsSelector } from 'core/widgets/SoundSettings';
import { WinnersTableSettings } from 'widgets/WinnersTableSettings';
import { PopupHistory } from 'components/PopupHistory';
import { getHistory } from 'core/widgets/History/actions';
import { HistoryTableResponsive } from 'components/PopupHistory/ResponsiveTable';
import { ScrollableContainer } from 'components/PopupGameRules/styles';
import { SettingsResponsive } from './Responsive';
import { SettingPanels } from './types';
import { SettingsWrapper } from './styles';
import { historySelector } from 'core/widgets/History';
import AudioService from 'services/AudioService';

export const Settings = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const openedPanel = useSelector(settingsPanelSelector);
  const hasClickJackpotSlot = useSelector(shasClickJackpotSlotSelector);
  const gameRule = useSelector(gameRuleSelector);
  const { gameSoundMute, soundEffects } = useSelector(soundSettingsSelector);
  const history = useSelector(historySelector);

  const [isFullScreenMode, setFullScreenMode] = useState<boolean>(false);
  const popupRef = useRef(null);
  const popupRef2 = createRef() as any;
  const popupRef4 = createRef() as any;
  const popupRef3 = useRef(null);
  const audioRefSettings = useRef(null) as any;

  const fullScreenHandler = useCallback(() => {
    const isFullscreen = checkFullScreen();

    setFullScreenMode(isFullscreen);
  }, []);

  useEffect(() => {
    const crossbrowserFullscreenEvents = [
      'fullscreenchange',
      'webkitfullscreenchange',
      'mozfullscreenchange',
      'msfullscreenchange',
    ];

    crossbrowserFullscreenEvents.forEach((eventType) =>
      document.addEventListener(eventType, fullScreenHandler),
    );

    return () => {
      crossbrowserFullscreenEvents.forEach((eventType) =>
        document.removeEventListener(eventType, fullScreenHandler),
      );
    };
  }, [fullScreenHandler]);

  const onFullScreenClick = useCallback(() => {
    if (!isFullScreenMode) {
      openFullScreen();
      return;
    }
    closeFullScreen();
  }, [isFullScreenMode]);

  const audioClick = useCallback(() => {
    if (!gameSoundMute && !soundEffects.mute) {
      AudioService.playClickSound('click', soundEffects.volume);
    }
  }, [gameSoundMute, soundEffects]);

  const onSettingsButtonClick = useCallback(
    (value: SettingPanels) => {
      audioClick();
      dispatch(manageSettingsPanelAction({ settingsPanel: value }));
    },
    [audioClick, dispatch],
  );

  const onCloseClick = useCallback(() => {
    audioClick();
    dispatch(manageSettingsPanelAction({ settingsPanel: null }));
    setIsOpen(false);
    dispatch(setIsShowJackpotRuleAction(false));
    setIsOpenJP(false);
  }, [audioClick, dispatch]);

  useEffect(() => {
    if (openedPanel === SettingPanels.Rules) {
      dispatch(getGameRulesAction(i18n.language as LANGUAGES));
    }

    if (openedPanel === SettingPanels.History) {
      dispatch(getHistory());
    }
  }, [dispatch, openedPanel, i18n.language]);

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenJP, setIsOpenJP] = useState(false);

  const handleOutsideClick = useCallback(
    (event: any) => {
      // @ts-ignore
      if (
        // @ts-ignore
        !popupRef3?.current?.contains(event.target)
      ) {
        if (hasClickJackpotSlot && !isOpenJP) {
          setIsOpenJP(true);
          return;
        }
        onCloseClick();
      }
    },
    [hasClickJackpotSlot, isOpenJP, onCloseClick],
  );

  const handleOutsideClickRules = useCallback(
    (event: any) => {
      if (isOpen && !popupRef2?.current?.contains(event.target)) {
        onCloseClick();
      }
    },
    [isOpen, onCloseClick, popupRef2],
  );

  useEffect(() => {
    if (
      isDesktop &&
      openedPanel !== SettingPanels.Rules &&
      openedPanel !== SettingPanels.Main &&
      openedPanel !== SettingPanels.History
    ) {
      if (openedPanel) {
        document.addEventListener('click', handleOutsideClick);
      } else {
        document.removeEventListener('click', handleOutsideClick);
      }
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [openedPanel, handleOutsideClick]);

  useEffect(() => {
    setIsOpen(openedPanel === SettingPanels.Rules || openedPanel === SettingPanels.History);
  }, [openedPanel]);

  useEffect(() => {
    if (
      (openedPanel === SettingPanels.Rules && gameRule.rules?.length) ||
      openedPanel === SettingPanels.History
    ) {
      document.addEventListener('click', handleOutsideClickRules);
    } else {
      document.removeEventListener('click', handleOutsideClickRules);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClickRules);
    };
  }, [openedPanel, handleOutsideClickRules, gameRule.rules?.length]);

  const jpRulesRef = useRef(null);
  const rulesContainerRef = useRef(null);

  useEffect(() => {
    if (gameRule.isShowJackpotRule && openedPanel && gameRule.rules?.length) {
      setTimeout(() => {
        const jpRules = jpRulesRef.current as any;
        const rulesContainer = rulesContainerRef.current as any;
        if (jpRules) {
          rulesContainer.scrollTop = jpRules.offsetTop - rulesContainer.offsetTop;
        }
      }, 1500);
    }
  }, [gameRule.isShowJackpotRule, openedPanel, gameRule.rules]);

  return (
    <div ref={popupRef}>
      <div ref={popupRef3}>
        <SettingsResponsive
          openedPanel={openedPanel}
          onSettingsButtonClick={onSettingsButtonClick}
          isFullScreen={isFullScreenMode}
          onFullScreenClick={onFullScreenClick}>
          {openedPanel === SettingPanels.Sound ? (
            <SoundSettingsPanel onCloseClick={onCloseClick} />
          ) : null}
          {openedPanel === SettingPanels.Video ? (
            <VideoSettingsPanel onCloseClick={onCloseClick} />
          ) : null}
          {openedPanel === SettingPanels.General ? (
            <GeneralSettingsPanel onCloseClick={onCloseClick} />
          ) : null}
          {openedPanel === SettingPanels.Main ? (
            <SettingsWrapper ref={popupRef4}>
              <MainSettingsPanel onCloseClick={onCloseClick} />
            </SettingsWrapper>
          ) : null}
          {openedPanel === SettingPanels.Winners ? (
            <WinnersTableSettings onCloseClick={onCloseClick} />
          ) : null}

          {openedPanel === SettingPanels.Rules ? (
            <PopupGameRules
              ref={popupRef2}
              onClose={onCloseClick}
              loading={!gameRule.rules?.length}>
              <ScrollableContainer ref={rulesContainerRef} $isMobile={isMobile}>
                <div dangerouslySetInnerHTML={{ __html: gameRule.rules }} />
                <div ref={jpRulesRef}>
                  {Boolean(gameRule.jackpotRules?.length) && (
                    <div dangerouslySetInnerHTML={{ __html: gameRule.jackpotRules as string }} />
                  )}
                </div>
              </ScrollableContainer>
            </PopupGameRules>
          ) : null}

          {openedPanel === SettingPanels.History ? (
            <PopupHistory ref={popupRef2} onClose={onCloseClick} loading={history === null}>
              <div ref={popupRef2}>
                <HistoryTableResponsive />
              </div>
            </PopupHistory>
          ) : null}
        </SettingsResponsive>
      </div>
    </div>
  );
};
